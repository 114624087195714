import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { BackgroundStyle } from "../App";
import Pano from "../Pano/Pano";
import Region360Logo from "../logo.svg";
import Intro from "./Intro";
import { simulateButtonPress, LocationFilters } from "../utils";

const Location = ({
  locations,
  introMode,
  setIntroMode,
  setBackgroundStyle,
}) => {
  const { t, i18n } = useTranslation();
  const [location, setLocation] = useState(null);
  const [nextLocation, setNextLocation] = useState(null);
  const [panoReady, setPanoReady] = useState(false);
  const { locationName: routeLocationName } = useParams();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isSmartphone = useMediaQuery({
    query: "(max-device-width: 586px)",
  });

  const INTRO_CITY_ROUTE = `${LocationFilters.CITIES.routeBase}/szczecin-kolo`;

  setBackgroundStyle(BackgroundStyle.DARK);

  useEffect(() => {
    const selectedLocation = routeLocationName
      ? locations.find((city) => city.route === routeLocationName)
      : getRandomLocation(locations);
    setLocation(selectedLocation);
    setNextLocation(
      getRandomLocation(locations, locations.indexOf(selectedLocation))
    );
  }, [locations, routeLocationName, i18n.language]);

  return !!location ? (
    <div className="h-full min-h-full">
      <Pano
        route={
          introMode
            ? INTRO_CITY_ROUTE
            : `${location.routeBase}/${location.route}`
        }
        setPanoReady={setPanoReady}
        isTouchEnabled={true}
      />
      {!introMode ? (
        <div
          className={`absolute top-0 w-full h-full justify-center flex flex-col bg-black bg-opacity-25 ${
            isDesktopOrLaptop && "pb-20"
          }`}
        >
          <div
            style={{ height: "30rem" }}
            className="flex flex-col justify-between items-center px-2"
          >
            <img
              style={{ width: isSmartphone ? "9rem" : "11rem" }}
              alt="Pomorze Zachodnie 360 - logo"
              src={Region360Logo}
            />
            {panoReady && (
              <div className="text-white flex flex-col items-center">
                <motion.h1
                  className="opacity-75 relative font-normal"
                  animate={{ opacity: [0, 1], top: [2, 0] }}
                  initial={{ opacity: 0, top: 2 }}
                  exit={{ opacity: 0, top: 2 }}
                >
                  {t("Discover").toUpperCase()}
                </motion.h1>
                <motion.h1
                  className="relative text-4xl font-bold text-center leading-none"
                  animate={{ opacity: [0, 1], top: [2, 0] }}
                  initial={{ opacity: 0, top: 2 }}
                  exit={{ opacity: 0, top: 2 }}
                >
                  {location.name}
                </motion.h1>
              </div>
            )}
            <motion.div
              animate={{ opacity: [0, 0, 0, 1] }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              className="flex flex-col w-2/3 xs:w-1/2 md:w-1/3 max-w-xs"
            >
              <a
                disabled={!panoReady}
                target="blank"
                href={`https://${location.url}`}
                onMouseOver={(e) => simulateButtonPress(e)}
                onMouseLeave={(e) => simulateButtonPress(e, false)}
                className="flex flex-row w-full sm:w-auto justify-center items-center py-3 flex-1 btn-thick bg-green-600 text-white text-sm xs:text-base relative"
              >
                START
              </a>
              <div className="flex flex-row flex-wrap justify-between mt-3">
                <Link
                  disabled={locations.length === 0}
                  to={`/mapa/${location.route}`}
                  onClick={() => setPanoReady(false)}
                  className="flex flex-row w-full sm:w-auto mx-1 my-2 justify-center items-center rounded-full py-3 px-10 flex-1 text-sm xs:text-base bg-gray-100 text-gray-700 font-bold"
                >
                  <span>{t("Map").toUpperCase()}</span>
                </Link>
                <Link
                  disabled={locations.length === 0}
                  onClick={() => setPanoReady(false)}
                  to={() =>
                    nextLocation
                      ? `${nextLocation.routeBase}/${nextLocation.route}`
                      : null
                  }
                  className="flex flex-row w-full mx-1 my-2 sm:w-auto justify-center items-center rounded-full py-3 flex-grow text-sm xs:text-base bg-gray-100 text-gray-700 font-bold"
                >
                  <span>{t("Next").toUpperCase()}</span>
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      ) : null}
      <AnimatePresence>
        {introMode && panoReady && (
          <Intro panoReady={panoReady} setIntroMode={setIntroMode} />
        )}
      </AnimatePresence>
    </div>
  ) : null;
};

const getRandomLocation = (cities, cityIndexToSkip) => {
  let randomIndex = cityIndexToSkip;
  while (randomIndex === cityIndexToSkip) {
    randomIndex = Math.round(Math.random() * (cities.length - 1));
  }
  return cities[randomIndex];
};

export default Location;
