import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Region360Logo from "../logo.svg";
import { simulateButtonPress } from "../utils";

const Intro = ({ setIntroMode, panoReady }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isSmartphone = useMediaQuery({
    query: "(max-device-width: 586px)",
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`absolute top-0 h-full w-full flex flex-col justify-center items-center bg-opacity-50 bg-black ${
        isDesktopOrLaptop && "pb-20"
      }`}
    >
      <div
        style={{ height: "30rem" }}
        className="flex flex-col items-center mx-6 lg:mx-64 justify-between"
      >
        <motion.div
          className="relative"
          style={{ width: isSmartphone ? "9rem" : "11rem" }}
          animate={{ opacity: [0, 1], top: [2, 0] }}
        >
          <img alt="Pomorze Zachodnie 360 - logo" src={Region360Logo} />
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 0, 1], top: [2, 2, 0] }}
          className="text-white flex flex-col"
        >
          <h1 className="justify-center text-center text-2xl xs:text-3xl">
            {t("Visit West-Pomerania")}
          </h1>
          <h1 className="justify-center text-center font-light text-2xl xs:text-3xl">
            {t("Discover attractions")}
          </h1>
        </motion.div>
        <motion.div
          className="flex flex-col w-11/12 xs:w-1/2 md:w-1/3 max-w-xs"
          animate={{
            opacity: [0, 0, 0, 1],
            top: [2, 2, 2, 0],
          }}
        >
          <button
            disabled={!panoReady}
            onClick={() => {
              setIntroMode(false);
              history.push("/mapa");
            }}
            onMouseOver={(e) => simulateButtonPress(e)}
            onMouseLeave={(e) => simulateButtonPress(e, false)}
            className="flex flex-1 justify-center
          items-center rounded-full text-sm xs:text-base
          text-white bg-green-600 font-bold relative btn-thick outline-none"
          >
            {t("Map").toUpperCase()}
          </button>
          <button
            onClick={() => setIntroMode(false)}
            className="flex flex-1 justify-center
          items-center rounded-full text-sm xs:text-base
          text-white border mt-6 hover:bg-white hover:text-gray-700 py-3 border-white font-bold outline-none"
          >
            <span>{t("Random Location").toUpperCase()}</span>
          </button>
        </motion.div>
      </div>
    </motion.div>
  );
};
export default Intro;
